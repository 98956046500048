import React, { Component, Fragment } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Button, Overlay,FormGroup,    } from "@blueprintjs/core";
import Dropzone from 'react-dropzone';
import DateField from '../component/DateField'; 
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleValueGlobal,handleIsGlobal, toast, intval, ft_confirm } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class OpenFileButton extends Component
{
    constructor(props) 
    {
        super(props);
        this.drop_ref = React.createRef();
        this.state = { "codes":false };
    }

    
    
    // componentDidMount()
    // {
    //    // 
    // }
    onDropped( files )
    {
        const reader = new FileReader();
        reader.addEventListener("loadend", (event) => {
            
            const data_array = event.target.result.split("\n").map( item => item = item.trim() );
            this.setState({"codes":data_array});
            // const sample = data_array.length > 1 ? `${data_array[0]}\n...\n${data_array[data_array.length-1]}` : `${data_array[0]}`;

            // if(  !window.confirm( `确定导入${data_array.length}条数据？样本:\n${sample}` )) return false;

            // 开始提交到服务器端

        });
        reader.readAsText(files[0]);

        //console.log( "here" );

        
        //const store = this.props.store;
        // store.openFile( files[0] );
        //console.log( files[0] );
    }

    cancle()
    {
        this.setState({"codes":false});
    }

    async upload()
    {
        if( !this.props.project_id || this.props.project_id < 1 )
        {
            alert("错误的project id");
            return false;
        }

        if( this.state.codes.length < 1 )
        {
            alert("要导入的数据不能可为空");
            return false;
        }

        
        const { data } = await this.props.store.project_upload_stock( this.props.project_id , this.state.codes , this.props.store.code_end_date );
        if( isApiOk( data ) )
        {
            console.log( data.data );
            toast("数据已经导入");
            if( this.props.onUpdated ) this.props.onUpdated();
            this.cancle();
        }
        else
            showApiError( data ); 
    }

    render()
    {
        const store = this.props.store;
        // const props = this.props;
        
        // return <div><Button icon="document-open" minimal={true} onClick={()=>this.onOpen()} />
        // <div className="dropzone">
        //     <Dropzone 
        //         //accept=".h2zip" 
        //         //multiple={false} 
        //         //ref={this.drop_ref} 
        //         //onDrop={(accepted, rejected) => { this.onDropped( accepted ) }}
        //     />
        // </div>
        // </div>;
        return <Fragment><Dropzone
        className="dropzone" 
        accept={this.props.accept}  
        maxSize={1024*1024*10}
        multiple={false} 
        ref={this.drop_ref} 
        onDrop={acceptedFiles => this.onDropped(acceptedFiles)}>
        <Button icon={this.props.icon} text={this.props.text} minimal={true} large={true} />
      </Dropzone>
      <Overlay isOpen={this.state.codes} className="std-overlay">
      <div className="overbox">
          <div className="centerbox">
              <h2>共有{this.state.codes.length}条数据可导入</h2>
              { this.state.codes.length > 1 ? <ul>
                  <li>{this.state.codes[0]}</li>
                  <li>...</li>
                  <li>{this.state.codes[this.state.codes.length-1]}</li>
              </ul> :  <div> {this.state.codes[0]} </div> }

              <div className="std-hr form-div"/>
              <FormGroup
                label="过期时间"
                labelFor="end_date"
                labelInfo="(必填)" 
            >

            <DateField  id="end_date" value={store.code_end_date} onChange={(value)=>store.code_end_date=value} placeholder="设置过期时间" />

            </FormGroup>
              
              <div className="std-hr form-div"/>
              <div className="std-form-actionbar">
              <Button large={true} onClick={(e)=>this.cancle()} minimal={true}>取消</Button>
              <Button large={true} onClick={(e)=>this.upload()}>导入</Button>
              </div>
              
          </div>
      </div>
      </Overlay></Fragment>
    }
}