import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import Column2Layout from '../component/Column2Layout'; 
import { Icon, AnchorButton, FormGroup, InputGroup, Switch, RadioGroup, Radio, Checkbox, TextArea, Button, Intent, ControlGroup } from "@blueprintjs/core";
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleValueGlobal,handleIsGlobal, toast } from '../util/Function';
import { DatePicker } from "@blueprintjs/datetime";
import DateField from '../component/DateField'; 
import ImageButton from '../component/ImageButton'; 
import EditorButton from '../component/EditorButton'; 

@withRouter
@inject("store")
@observer
export default class ProjectAdd extends Component
{
   
    async submit()
    {
        const { store } = this.props;
        // 检查必填项
        if( store.project_name.length < 1 ) return toast("项目名称不能为空");
        if( store.project_subtitle.length < 1 ) return toast("子标题不能为空");
        if( parseInt(store.project_target_amount) < 1 ) return toast("目标价格不能为零");
        if( parseInt(store.project_item1_price) < 1 ) return toast("商品单价不能为零");

        if( store.project_desp.length < 1 ) return toast("项目介绍不能为空");

        if( store.project_item1_name.length < 1 ) return toast("商品名称不能为空");
        
        const { data } = await store.project_save();
        if( isApiOk( data ) )
        {
            if( data.data && data.data.id )
            {
                // 清理临时课程数据
                store.project_data_init();   
                toast("项目添加完成，转向项目列表");
                this.props.history.replace("/project");
            }
            //console.log( data );
        }
        else showApiError( data );
       
        // console.log( store );
    }
    
    uploaded( url )
    {
        //this.setState({"":});
        const { store } = this.props;
        store.project_desp += "\r\n"+'![图片]('+url+')';
    }
    
    
    render()
    {
        const store = this.props.store;
        
        const main = <div className="wrapper std-form">
        <FormGroup
            label="项目名称"
            labelFor="name"
            labelInfo="(必填)" 
        >
        <InputGroup id="name" placeholder="10个字以内" large="true" onChange={(e)=>handleStringGlobal(e,'project_name')} value={store.project_name}/>
        </FormGroup>

        <FormGroup
            label="子标题"
            labelFor="subtitle"
            labelInfo="(必填)" 
        >
        <InputGroup id="subtitle" placeholder="20个字以内" large="true" onChange={(e)=>handleStringGlobal(e,'project_subtitle')} value={store.project_subtitle}/>
        </FormGroup> 

        <FormGroup
            label="预售总价"
            labelFor="total_amount"
            labelInfo="(必填)" 
        >
        <InputGroup id="total_amount" placeholder="元" large="true" onChange={(e)=>handleValueGlobal(e,'project_target_amount')} value={store.project_target_amount}/>
        </FormGroup>  

        <FormGroup
            label="结束日期"
            labelFor="end_date"
            labelInfo="(必填)" 
        >

        <DateField  id="end_date" value={store.project_end_date} onChange={(value)=>store.project_end_date=value} placeholder="选择预售结束日期" />

        </FormGroup> 

        <FormGroup
            label="商品名称"
            labelFor="item_name1"
            labelInfo="(必填)" 
        >
        <InputGroup id="item_name1" placeholder="10个字以内" large="true" onChange={(e)=>handleStringGlobal(e,'project_item1_name')} value={store.project_item1_name}/>
        </FormGroup> 

        <FormGroup
            label="商品简介"
            labelFor="item_detail1"
            labelInfo="(必填)" 
        >
        <InputGroup id="item_detail1" placeholder="10个字以内" large="true" onChange={(e)=>handleStringGlobal(e,'project_item1_detail')} value={store.project_item1_detail}/>
        </FormGroup> 

        <FormGroup
            label="商品单价"
            labelFor="item_price"
            labelInfo="(必填)" 
        >
        <InputGroup id="item_price" placeholder="元" large="true" onChange={(e)=>handleValueGlobal(e,'project_item1_price')} value={store.project_item1_price}/>
        </FormGroup>  

        <div className="std-hr form-div"/>

        <FormGroup
            label="介绍视频链接"
            labelFor="video_url" 
        >
        <InputGroup id="video_url" placeholder="10个字以内" large="true" onChange={(e)=>handleStringGlobal(e,'project_video_url')} value={store.project_video_url}/>
        </FormGroup> 

        <FormGroup
            label="项目详细说明"
            labelFor="desp"
            labelInfo="(必填)"
        >
        <TextArea className="std-area" large="true" placeholder="详细介绍项目内容、预售状态、定价和发售相关信息。支持Markdown语法，可上传图片，图片宽度建议800px。" onChange={(e)=>handleStringGlobal(e,'project_desp')} value={store.project_desp}/>
        </FormGroup>

        <FormGroup>
        <div className="row-half">
            <div className="left">
                <EditorButton field="project_desp"/><ImageButton markdown={true} onUploaded={(url)=>this.uploaded(url)} title="上传图片"/>
            </div>
            <div className="right">
                <Button large="true" intent={Intent.PRIMARY} onClick={()=>this.submit()}>提交</Button>
            </div>
        </div>
        
        </FormGroup> 


        </div>;

        const side = <div><div className="wrapper info-box">
        <AnchorButton large="true" icon="link" minimal={true} href="https://get.ftqq.com/9076.get" target="_blank">Markdown教程</AnchorButton>
        </div></div>;

        const title = <><span>我的项目</span><Icon icon="chevron-right" /><span className="active">新增项目</span></>;

        return <Column2Layout main={main} side={side} title={title} />;
    }
}