import React, { Component, Fragment } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import MenuList from '../component/MenuList'; 
import UserMenu from '../component/UserMenu'; 
import UserAvatar from '../component/UserAvatar'; 
import UserName from '../component/UserName'; 


@withRouter
@inject("store")
@observer
export default class Header extends Component
{
    render()
    {
        const no_menu = this.props.noMenu ? true:false;
        return <div className="std-header">
        <div className="wrapper center-v left-logo-bg">
            <div className="header-box">
            <h1 className="title"><img src="/image/logotext.png" /></h1>
            { no_menu ? null : <div className="menu">
                <MenuList />
            </div> }
            
            <div className="action">
            {  this.props.store.nickname && <Fragment><UserAvatar /><UserName/><UserMenu /></Fragment> }
            
            </div>
            </div>
            
        </div>
    </div> ;
    }
}