import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';


import DocumentTitle from 'react-document-title';

@withRouter
@inject("store")
@observer
export default class Home extends Component
{
    componentDidMount()
    {
        const id = 32;
        this.props.history.replace("/"+id);
    }
    
    render()
    {
        const main = <div></div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}