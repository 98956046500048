import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Menu, MenuItem, Popover, Position } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class UserMenu extends Component
{
    render()
    {
        const exampleMenu = (
            <Menu>
                {/* <MenuItem icon="settings" text="设置" onClick={()=>this.props.history.push("/settings")} /> */}
                {/* <MenuDivider /> */}
                <MenuItem icon="log-out" text="退出" onClick={()=>this.props.history.push("/logout")} />
            </Menu>
        );
        return  <div>{ this.props.store.nickname && <Popover content={exampleMenu} position={Position.BOTTOM}>
        <Button rightIcon="caret-down" minimal={true} />
        </Popover>}</div>;
    }
}