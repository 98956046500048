import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';


import DocumentTitle from 'react-document-title';

@withRouter
@inject("store")
@observer
export default class Redrect extends Component
{
    
    
    render()
    {
        
        
        //const main = <div>Redrect</div>;
        //return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}