import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import { isApiOk , showApiError, toast, intval, ft_confirm } from '../util/Function';
import { Icon, AnchorButton, FormGroup, InputGroup, Switch, RadioGroup, Radio, Checkbox, TextArea, Button, Intent, ControlGroup } from "@blueprintjs/core";



import DocumentTitle from 'react-document-title';

@withRouter
@inject("store")
@observer
export default class JsPay extends Component
{
    state = {"pay_url":null,"paid":false}
    
    async componentDidMount()
    {
        const id = intval(this.props.match.params.id);
        if( id > 0 )
        {
            
            
            const { data } = await this.props.store.project_order_js( id );
            if( isApiOk( data ) )
            {
                
            }
            else
                showApiError( data ); 
        }
    }

    clicked()
    {
        ft_confirm( "支付完成后点此继续" , () =>
        {
            this.setState({"paid":true});
        } );
    }
    
    render()
    {
        const main = <div className="pay-box">
    
        { this.state.paid ? <div className="paid-notice">
            <div>请关注微信服务号[方糖]</div>
            <div>以接收发货通知，查询订单和更新。</div>
            <img src="/image/mpcover.png" />
            
        </div> : this.state.pay_url ? <AnchorButton target="_blank" large={true} href={this.state.pay_url} onClick={()=>this.clicked()} >点此继续</AnchorButton> : <div>正在生成订单...</div>       }

           
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}