import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Intent, ProgressBar, Callout, Button, Overlay } from "@blueprintjs/core";
import { isMobile, isWechat } from '../util/Function';
import QRCode from 'qrcode.react';

@withRouter
@inject("store")
@observer
export default class BuyButton extends Component
{
    state = {"qrcode":null}
    
    buy( pid )
    {
        const url = process.env.REACT_APP_CLIENT_WEBSITE + 'project/pay/'+pid;
        
        if( isMobile() )
        {
            // 如果在手机上
            // 转向到支付页面
            window.location = url;
            //this.props.history.replace("project/pay/"+pid); 

            // if( isWechat() )
            // {
            //     this.props.history.replace("project/jspay/"+pid);
            // }
            // else
            // {
            //     this.props.history.replace("project/pay/"+pid); 
            // }
            
        }
        else
        {
            
            this.setState({"qrcode":url});
        }
        
    }

    onClose( e )
    {
        this.setState({"qrcode":null});
    }
    
    
    render()
    {
        return this.state.qrcode ? <Overlay canEscapeKeyClose={true}  isOpen={this.state.qrcode.length>0} onClose={(e)=>this.onClose(e)}>
            <div className="qrdiv">
            <h3>请用手机扫码以继续</h3>
            <QRCode value={this.state.qrcode} />
            </div>
            
        </Overlay>    
        : 
        <Button large={true} intent={Intent.PRIMARY} onClick={()=>this.buy(this.props.pid)}>{this.props.children}</Button>;
    }
}