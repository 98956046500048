import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import { isApiOk , showApiError, toast, intval } from '../util/Function';
import OnePageLayout from '../component/OnePageLayout'; 
import ReactMarkdown from 'react-markdown';
import ImageZoom from 'react-medium-image-zoom';
import { Intent, ProgressBar, Callout, Button } from "@blueprintjs/core";
import ReactPlayer from 'react-player';
import BuyButton from '../component/BuyButton'; 


function LinkRenderer(props) 
{
    return <a href={props.href} target="_blank">{props.children}</a>
}

function ImageZoomed( props )
{
    return <ImageZoom  
    image={{ src: props.src }} 
    />
}

@withRouter
@inject("store")
@observer
export default class ProjectLanding extends Component
{
    
    async componentDidMount()
    {
        const id = intval(this.props.match.params.id);
        if( id > 0 )
        {
            const { data } = await this.props.store.project_landing( id );
            if( isApiOk( data ) )
            {
                this.props.store.project_set_data( data.data );
                console.log( "storeinfo" , this.props.store );
            }
            else
                showApiError( data ); 
        }
    }
    
    render()
    {
        const store = this.props.store;

        
        const main = <div className="landing-box">
            <h1>{store.project_name}</h1>
            <h2 className="subtitle">{store.project_subtitle}</h2>
            { store.project_video_url && <ReactPlayer controls={true} url={store.project_video_url} width="100%" height="80%" /> }
            <div className="desp">
            <ReactMarkdown source={store.project_desp} renderers={{link: LinkRenderer,image:ImageZoomed}}/></div>
        </div>;

        const process = parseFloat(store.project_current_amount/store.project_target_amount);

        console.log( "process" , process );

        const side = <div><div className="wrapper info-box landing price-box">

        { store.project_type == 1 ? <Fragment>
            { store.project_is_in_sale == 1 ? <>
            <div className="item-price">¥{store.project_item1_price}<span>预售价</span></div>
            <div className="item-name">{store.project_item1_name}</div>
            <div className="item-detail">{store.project_item1_detail}</div>
            { store.project_can_buy == 1 ? <BuyButton pid={store.project_id}>买买买</BuyButton> : <Button large={true} disabled={true}>暂停销售</Button> }
            
            <Callout className="mt10">如截止时预售未达目标金额，将直接退款。</Callout> 
            <div className="divider"></div>
            预售截止：{store.project_end_date}
            </>:
            
            <Callout className="mt10">预售已结束</Callout>
            }    

            <div className="divider"></div>

            { process > 0.1 ? 
            
            <><h1 className="price">累销 ¥{store.project_current_amount}元</h1> 
            <ProgressBar animate={false} value={store.project_current_amount/store.project_target_amount} intent={Intent.PRIMARY}/>  <div className="explain mt5">目标金额：¥{store.project_target_amount}元</div></> 
            
            :
            
            <><h1 className="price">预售目标：¥{store.project_target_amount}元</h1></>   }

        </Fragment> : <div>标准</div>  }
        
         
        
      
        


        
        

        


        </div></div>;
        const title = store.project_name;
        return <OnePageLayout main={main} side={side} title={title} />;
    }
}