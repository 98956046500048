import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import CenterLayout from '../component/CenterLayout'; 

@withRouter
@inject("store")
@observer
export default class Logout extends Component
{
    componentDidMount()
    {
        this.props.store.cleanUser();
        this.props.history.replace("/login");
    }

    render()
    {
        const main = <div>Logout...</div>;
        return <CenterLayout main={main} />;
    }
}