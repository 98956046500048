import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { Tooltip, Button, ButtonGroup, Intent, Slider, Icon } from "@blueprintjs/core";
import copy from 'copy-to-clipboard';
import { isApiOk , showApiError, toast } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class ImageButton extends Component
{
    async handleDrop( dropped ) 
    {
        toast("正在上传图片，请稍候");
        const { data } = await this.props.store.uploadImage( dropped[0] );
        if( isApiOk( data ) )
        {
            // 封面图片上传完成，地址为 data.data.url
            if( !data.data.url || data.data.url.length < 1 )
            {
                toast("图片上传失败，请重试");
                return false;
            }
            else
            {
                
                if( this.props.onUploaded )
                {
                    this.props.onUploaded( data.data.url );
                }
                else
                {
                    const text = this.props.markdown ? '![图片说明](' + data.data.url + ')' :  data.data.url ;
                    copy( text , { message: '图片已上传，按 #{key} 复制到剪切板' } );
                }
            }
        }else
            showApiError( data ); 
    }
    
    render()
    {
        const icons = <Icon icon="media" color="#CCCCCC" iconSize={24} />;
        const button = this.props.button ? this.props.button  : <Button icon={icons} minimal={true} large={true}  />
        const tips = this.props.title ? this.props.title : "点此上传图片";

        return <div>
            <Tooltip content={tips}>
                <Dropzone className="dropzone" accept="image/png,image/jpg,image/jpeg,image/gif" multiple={false} onDrop={(e)=>this.handleDrop(e)}>
                    {button}
                </Dropzone>
            </Tooltip>
        </div>;
    }
}