import React, { Component, Fragment } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import { intval } from '../util/Function';
import ReactMustache from 'react-mustache';
import { Icon, AnchorButton, FormGroup, InputGroup, Switch, RadioGroup, Radio, Checkbox, TextArea, Button, Intent, ControlGroup } from "@blueprintjs/core";
import moment from 'moment';
import 'moment/locale/zh-cn.js';
moment.locale('zh-cn');

@withRouter
@inject("store")
@observer
export default class ProjectBox extends Component
{
    render()
    {
        const data = this.props.data;
        const classname = this.props.className ? this.props.className : '';

        data.end_date = moment(data.end_date).format("YYYY-MM-DD") ;

        return data.name && data.name.length < 1 ? null : <div className={"project-box-item " + classname }>
        
        <div className="row">
            <div className="desp">
                <div className="name"><Link to={'/project/landing/'+data.id} target="_blank">{data.name}</Link></div>
                <div className="title">{data.subtitle}</div>
                <div className="priceline">
                <span className="price">单价 ¥{(data.item1_price_cent/100).toFixed(2)} · 总目标 ¥{(data.target_amount_cent/100).toFixed(2)} </span> <span className="explain">{data.end_date}结束</span>
                </div>
                
            </div>
            <div className="actionbox">
                
                <AnchorButton className="action" large="true" onClick={()=>this.props.history.push("/project/manage/"+data.id)}>管理</AnchorButton>

                <AnchorButton className="action" large="true" onClick={()=>this.props.history.push("/project/modify/"+data.id)}>编辑</AnchorButton>
            </div>
        </div>

        </div>;
    }
}