import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import { isApiOk , showApiError, toast, intval } from '../util/Function';
import OnePageLayout from '../component/OnePageLayout'; 
import ReactMarkdown from 'react-markdown';
import ImageZoom from 'react-medium-image-zoom';
import { Intent, ProgressBar, Callout, Button } from "@blueprintjs/core";
import ReactPlayer from 'react-player';
import BuyButton from '../component/BuyButton2'; 


function LinkRenderer(props) 
{
    if( props.href.substr( -4 ).toLowerCase() == '.mp4'  )
        return <ReactPlayer controls={true} url={props.href} width="100%" height="80%" />;
    else
        return <a href={props.href} target="_blank">{props.children}</a>
}


function ImageZoomed( props )
{
    return <ImageZoom  
    image={{ src: props.src }} 
    />
}

@withRouter
@inject("store")
@observer
export default class ProjectLandingNew extends Component
{
    state = {"loading":true,"code":false}

    async componentDidMount()
    {
        const id = intval(this.props.match.params.id);
        if( id > 0 )
        {
            const cache = new URLSearchParams(this.props.location?.search).get('cache') || 0;

            let cache_data = false; 
            if( cache )
            {
                try {
                    cache_data = await this.props.store.raw_get("/data/"+id+".json");
                } catch (error) {
                    cache_data = await this.props.store.project_landing( id );
                }
            }else
            {
                cache_data = await this.props.store.project_landing( id );
            }
            
            const { data } = cache_data;

            if( isApiOk( data ) )
            {
                this.props.store.project_set_data( data.data );
                // console.log( " this.project_end_date " , this.props.store.project_end_date );
            }
            else
                showApiError( data );
                
            this.setState( {"loading":false} );    
        }
    }
    
    render()
    {
        const store = this.props.store;
        const code = this.props.match.params.code;
        

        
        const main = <div className="landing-box">
            <h1>{store.project_name}</h1>
            <h2 className="subtitle">{store.project_subtitle}</h2>
            { code && <div className="code">您的课程领取码为： {code}</div> }
            { store.project_video_url && <ReactPlayer controls={true} url={store.project_video_url} width="100%" height="80%" /> }
            <div className="desp">
            <ReactMarkdown source={store.project_desp} renderers={{link: LinkRenderer,image:ImageZoomed}}/></div>
        </div>;

        const process = parseFloat(store.project_current_amount/store.project_target_amount);

        // console.log( "process" , process );

        const side = <div><div className="wrapper info-box landing price-box">

        { store.project_type == 1 ? <>{ store.project_is_in_sale == 1 ? <>
        <div className="item-price">¥{store.project_item1_price}<span>预售价</span></div>
        <div className="item-name">{store.project_item1_name}</div>
        <div className="item-detail">{store.project_item1_detail}</div>
        { store.project_can_buy == 1 ? <BuyButton pid={store.project_id}>买买买</BuyButton> : <Button large={true} disabled={true}>暂停销售</Button> }
        
        <Callout className="mt10">如截止时预售未达目标金额，将直接退款。</Callout> 
        <div className="divider"></div>
        预售截止：{store.project_end_date}
        </>:
        
        <Callout className="mt10">预售已结束</Callout>
        }    

        <div className="divider"></div>

        { process > 0.1 ? 
        
        ((process > 1.0 && true) ? <><h1 className="price">{store.project_target_amount}+</h1>
        <ProgressBar animate={false} value={1.0} intent={Intent.PRIMARY}/>  
        <div className="explain mt5">此项目众筹金额已达标</div>
        </> :
        <><h1 className="price">累销 ¥{store.project_current_amount}元</h1> 
        <ProgressBar animate={false} value={store.project_current_amount/store.project_target_amount} intent={Intent.PRIMARY}/>  <div className="explain mt5">目标金额：¥{store.project_target_amount}元</div></> )
        
        :
        
        <><h1 className="price">预售目标：¥{store.project_target_amount}元</h1></> 
        
        }</>  : <>
        <div className="item-price">¥{store.project_item1_price}<span>{store.project_stock_count}件可买</span></div>
        <div className="item-name">{store.project_item1_name}</div>
        <div className="item-detail">{store.project_item1_detail}</div>
        <div className="divider"></div>
        { store.project_can_buy == 1 ? 
            ( store.project_stock_count > 0 ? <BuyButton pid={store.project_id}>买买买</BuyButton> : <Callout className="mt10">已售罄，补货中</Callout> ) :  <Callout className="mt10">暂停购买</Callout> }
        </> }
        


        
        

        


        </div></div>;
        const title = store.project_name;

        return this.state.loading ? <div className="loading-box"><ProgressBar className="pbar"/></div> :
         <OnePageLayout main={main} side={side} title={title} />;
    }
}