import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Tooltip } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class UserAvatar extends Component
{
    render()
    {
        const avatar = this.props.store.avatar;
    
        return <span><Tooltip content={this.props.store.nickname}>{ avatar && <img src={avatar} className="avatar-icon" alt="avatar" /> }</Tooltip></span>;
    }
}