import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Intent, ProgressBar, Callout, Button, Overlay } from "@blueprintjs/core";
import { isMobile, isWechat } from '../util/Function';
import QRCode from 'qrcode.react';

@withRouter
@inject("store")
@observer
export default class BuyButton2 extends Component
{
    state = {"qrcode":null}
    
    buy( pid )
    {
        const url = process.env.REACT_APP_CLIENT_WEBSITE + 'project/pay/'+pid;
        
        const url2 = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + encodeURIComponent( process.env.REACT_APP_WECHAT_APPID  ) + '&redirect_uri=' + encodeURIComponent( process.env.REACT_APP_CLIENT_WEBSITE + 'openid/' )  + '&response_type=code&scope=snsapi_base&state='+pid+'#wechat_redirect';
        
        if( isMobile() )
        {
            // 如果在手机上
            // 转向到支付页面
            
            //this.props.history.replace("project/pay/"+pid); 

            if( isWechat() )
            {
                // 检测openid
                if( !window.localStorage.getItem("NOPID") )
                {
                    // 转向到授权页
                    

                    window.location = url2;
                }
            }
            else
            {
                window.location = url;
            }
            
        }
        else
        {
            
            this.setState({"qrcode":url2});
        }
        
    }

    onClose( e )
    {
        this.setState({"qrcode":null});
    }
    
    
    render()
    {
        return this.state.qrcode ? <Overlay canEscapeKeyClose={true}  isOpen={this.state.qrcode.length>0} onClose={(e)=>this.onClose(e)}>
            <div className="qrdiv">
            <h3>请用微信扫码以继续</h3>
            <QRCode value={this.state.qrcode} />
            </div>
            
        </Overlay>    
        : 
        <Button large={true} intent={Intent.PRIMARY} onClick={()=>this.buy(this.props.pid)}>{this.props.children}</Button>;
    }
}