import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import Column2Layout from '../component/Column2Layout'; 
import { Button, Callout } from "@blueprintjs/core";
import { toast , isApiOk , showApiError } from '../util/Function';
import ProjectBox from '../component/ProjectBox'; 
import NodataBox from '../component/NodataBox'; 


@withRouter
@inject("store")
@observer
export default class ProjectList extends Component
{
    state = {"project_list":[]};
    
    async componentDidMount()
    {
        this.loadProject();
    }

    async loadProject()
    {
        const { data } = await this.props.store.project_list();
        if( isApiOk( data ) )
        {
            this.setState( {"project_list":data.data} );
        }
        else
            showApiError( data );
    }
    
    render()
    {
        const project_list = this.state.project_list;
        const text = <div>还没有项目，<Link to="/project/add">点此新增</Link></div>;
        const main = <div className="wrapper">
        { project_list.length > 0 ? project_list.map( (item)=><ProjectBox data={item} key={item.id} /> )
        :
            <NodataBox text={text}/>
        }
        </div>;

        const side = <div><div className="wrapper info-box">
        <Button large="true" icon="plus" minimal="true" onClick={()=>this.props.history.push("/project/add")}>发布新项目</Button>
        </div></div>;

        const title = <span>我的项目</span>;
        
        return <Column2Layout main={main} side={side} title={title} />;
    }
}