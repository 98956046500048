import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

@withRouter
@inject("store")
@observer
export default class UserName extends Component
{
    render()
    {
        const nickname = this.props.store.nickname ;
        return <span className="avatar-name">{nickname}</span>;
    }
}