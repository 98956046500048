import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import { isApiOk , showApiError, toast, intval, ft_confirm, isMobile, isWechat, isAlipay } from '../util/Function';
import { Icon, AnchorButton, FormGroup, InputGroup, Switch, RadioGroup, Radio, Checkbox, TextArea, Button, Intent, ControlGroup } from "@blueprintjs/core";

import DocumentTitle from 'react-document-title';

@withRouter
@inject("store")
@observer
export default class Pay extends Component
{
    state = {"pay_url":null,"paid":false}
    
    async componentDidMount()
    {
        const id = intval(this.props.match.params.id);
        if( id > 0  &&  !isWechat() && !isAlipay()  )
        {
            const { data } = await this.props.store.project_order( id );
            if( isApiOk( data ) )
            {
                if( data.data.mweb_url )
                {
                    // const rurl = data.data.mweb_url + '&redirect_url=' + encodeURIComponent( process.env.REACT_APP_CLIENT_WEBSITE + 'payed/'+id );


                    this.setState({"pay_url":data.data.mweb_url});
                }
                // this.props.store.project_set_data( data.data );
                // console.log( " this.project_end_date " , this.props.store.project_end_date );
            }
            else
                showApiError( data ); 
        }
    }

    clicked()
    {
        ft_confirm( "支付完成后点此继续" , () =>
        {
            this.setState({"paid":true});
        } );
    }
    
    render()
    {
        const in_wechat = isWechat() ;
        const in_alipay = isAlipay() ;
        
        const main = <div className="pay-box">
    
        { in_wechat || in_alipay  ? 
            <div className="paid-notice">
                <div>请点右上角菜单，选择「在浏览器打开」</div>
            </div>
        : this.state.paid  ? 
         <div className="center">
            <div className="paid-notice">请关注微信服务号[方糖]，以接收发货通知。
            <img src="/image/mpcover.png" /> 
            </div>    
            
        </div> : this.state.pay_url ? <><AnchorButton large={true} href={this.state.pay_url} onClick={()=>this.clicked()} className="btn">点此继续，如已经支付成功，关注即可</AnchorButton><div className="center">
            <div className="paid-notice">请关注微信服务号[方糖]，以接收发货通知。
            <img src="/image/mpcover.png" /> 
            </div>
             
        </div></>  : <div>正在生成订单...</div>  
    
    }
        
        
        
           
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}