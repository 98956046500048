import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Login from './screen/Login';
import Logout from './screen/Logout'; 
import ProjectList from './screen/ProjectList';
import ProjectAdd from './screen/ProjectAdd'; 
import ProjectModify from './screen/ProjectModify'; 
import ProjectLanding from './screen/ProjectLanding'; 
import ProjectLandingNew from './screen/ProjectLandingNew'; 
import ProjectManage from './screen/ProjectManage'; 
import ProjectStock from './screen/ProjectStock'; 

import OpenId from './screen/OpenId'; 
import Home from './screen/Home'; 


import Pay from './screen/Pay'; 
import Order from './screen/Order'; 
import JsPay from './screen/JsPay'; 

import Redrect from './screen/Redrect'; 

import AlertDiv from './component/AlertDiv';




class App extends Component {
  render() {
    return (
      <Router>
        <>
        <Switch>
          <Route path="/r/:base64" component={Redrect} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/project/modify/:id" component={ProjectModify} />
          <Route path="/project/landing/:id/:code?" component={ProjectLandingNew} />
          <Route path="/project/landingnew/:id/:code?" component={ProjectLandingNew} />
          <Route path="/project/stock/:id" component={ProjectStock} />
          <Route path="/project/manage/:id" component={ProjectManage} />
          <Route path="/project/pay/:id" component={Pay} />
          <Route path="/project/jspay/:id" component={JsPay} />
          <Route path="/project/add" component={ProjectAdd} />
          <Route path="/project" component={ProjectList} />
          <Route path="/openid" component={OpenId} />

          <Route path="/order/:openid" component={Order} />
          <Route path="/:id/:code?" component={ProjectLandingNew} />
          <Route path="/" component={Home} />
          
        </Switch>
        <AlertDiv />
        </>
      </Router>  
    );
  }
}

export default App;
