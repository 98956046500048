import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import { isApiOk , showApiError, toast, intval } from '../util/Function';
import SoloLayout from '../component/SoloLayout'; 
import StatusIcon from '../component/StatusIcon'; 
import { CopyToClipboard } from 'react-copy-to-clipboard';


@withRouter
@inject("store")
@observer
export default class Order extends Component
{
    state = {"items":null,"code":null,"notice":null};
    
    async componentDidMount()
    {
        const openid = this.props.match.params.openid;
        if( openid )
        {
            if( openid == 'oJs-GwGuT8e3a7YsaUcIkDAVPJn4' )
            {
                this.setState({"notice":<div className="notice-box">
                    <div className="title">《订阅课》和《完免课》今年合并为《方糖技能栈》stack.ftqq.com，收录有方糖的实战类课程，未来的实战课也会上架到这里。<br/><br/>

                    为感谢一直以来大家对众筹的支持，在方糖Next支持超过400元的同学都会收到一张399元面额的充值卡，可在方糖公众号菜单「Next订单」页面查看提取密码，月底前有效。</div>
                    <div className="mt10"><img style={{"width":"100%"}} src="/image/gg.jpeg"/></div>
                </div>});
            }else
            {
                const { data } = await this.props.store.order( openid );
                if( isApiOk( data ) )
                {
                    this.setState({"items":data.data.orders,"code":data.data?.code??null});
                }
                else
                    showApiError( data ); 
            }
            
            
        }
    }
    
    render()
    {
        const state = this.state;
        const main = <div className="order-box">
        { state.notice ? state.notice : <>{ state. code && <div className="notice-box">
            <div className="title">《订阅课》和《完免课》今年合并为《方糖技能栈》stack.ftqq.com，收录有方糖的实战类课程，未来的实战课也会上架到这里。<br/><br/>

            为感谢一直以来大家对众筹的支持，在方糖Next支持超过400元的同学都会收到一张399元面额的充值卡，可在方糖公众号菜单「Next订单」页面查看提取密码，月底前有效。</div>
            <div className="box">
                <CopyToClipboard text={state.code} onCopy={()=>alert('已复制到剪贴板')}><div className="line">充值卡提取密码<span>{state.code}</span></div></CopyToClipboard>
                <div>使用地址：<a href="https://stack.ftqq.com/recharge" target="_blank">stack.ftqq.com/recharge</a></div>
            </div>
            
        </div>}</> }

        { state.items ? state.items.map( (item) =>
            {
                return <div className="item" key={item.id}>
                <h2>{item.item_name} </h2>
                <div className="row">
                    <div className="price">¥{item.total_fee/100}元 · 订单号 {item.id}</div>
                    <div className="time">{item.created_at}</div>
                    {item.code && item.code.length > 0 && <div className="code">提取密码：{item.code} &nbsp;</div>}
                    {item.tips && item.tips.length > 0 && <div className="tips">{item.tips} &nbsp; </div>}
                    
                    <div className="status"><StatusIcon data={item.refund_status} /></div>
                </div>
                
                </div>
            } ) : state.notice ? '' : <div>没有最近的订单</div> }
        </div>;
        return <SoloLayout main={main} title={'订单查询'} />;
    }
}