import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Alert , Intent } from '@blueprintjs/core';

@withRouter
@inject("store")
@observer
export default class AlertDiv extends Component
{
    onClose()
    {
        this.props.store.alert_open = false;
    }

    onClose2()
    {
        this.props.store.confirm_open = false;
    }
    
    render()
    {
        const store = this.props.store;

        return <>
        
        <Alert isOpen={store.alert_open} confirmButtonText="确定" onClose={()=>this.onClose()} intent={Intent.PRIMARY} >{store.alert_content}</Alert>

        <Alert isOpen={store.confirm_open} cancelButtonText="取消" confirmButtonText="确定" onClose={()=>this.onClose2()} onCancel={()=>this.onClose2()} onConfirm={()=>store.confirm_confirm()} intent={Intent.PRIMARY} >{store.confirm_content}</Alert>

        </>;
    }
}