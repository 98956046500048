import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Header from '../component/Header'; 

@withRouter
@inject("store")
@observer
export default class OnePageLayout extends Component
{
    render()
    {
        const main = this.props.main ? this.props.main : null; 
        const side = this.props.side ? this.props.side : null; 
        const title = this.props.title ? this.props.title : null; 

        return <DocumentTitle title={title + '' + this.props.store.appname}><div>
        <Header noMenu={true} />   
        <div className="wrapper std-box lightgray">
            <div className="row">
                <div className="main">
                    {main}
                </div>
                <div className="side">
                    {side}
                </div>
            </div>
            
        </div>

        </div></DocumentTitle>;
    }
}