import AppState from '../store/AppState';
import AppToaster from './Toaster';
import { sprintf } from 'sprintf-js';
import { Object } from 'core-js';
import store from '../store/AppState';
//const t = i18n.getI18nTranslate();

//translate.setI18n(i18n);
export function isMobile()
{
    var width = window.innerWidth
|| document.documentElement.clientWidth
|| document.body.clientWidth;

    return width <= 768;
}

export function isWechat()
{
    return navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger";
}

export function isAlipay()
{
    return navigator.userAgent.toLowerCase().match(/Alipay/i) == "alipay";
}

export function handleBooleanGlobal( e , name ) {
    if( name.indexOf('.') > 0 )
    {
        const infos = name.split('.');
        if( AppState[infos[0]] ) AppState[infos[0]][infos[1]] = e.target.checked;
        if( infos[0] === 'user' ) AppState.saveData();
    }
    else
        AppState[name] = e.target.checked;
    
        // console.log(AppState);
}

export function handleIsGlobal( e , name ) {
    if( e.target.checked )
        AppState[name] = 1;
    else    
        AppState[name] = 0;
}

export function baseName(str)
{
   var base = new String(str).substring(str.lastIndexOf('/') + 1); 
    if(base.lastIndexOf(".") != -1)       
        base = base.substring(0, base.lastIndexOf("."));
   return base;
}

export function maxid( array ) {
    let maxid = 0;
    array.forEach( ( item ) => 
    {
        if( maxid < item.id ) maxid = item.id;
    } );

    return maxid;
}

export function maxorder( array ) {
    let maxorder = 0;
    array.forEach( ( item ) => 
    {
        if( maxorder < item.order ) maxorder = item.order;
    } );

    return maxorder;
}

export function firstorder( array )
{
    return array[0].order;
}

export function ftsort( ftArrayList )
{
    return ftArrayList.sort( ( a , b ) => a.order - b.order );
    // ftArrayList;
}

export function nextorder( array , current_order ) {
    let nextorder = 0;
    let dobreak = false;
    let find = false;
    array.forEach( ( item ) => 
    {
        if( find )
        {
            nextorder = item.order;
            dobreak = true;
            find = false;
        } 
        if( dobreak ) return false;
        if( current_order == item.order ) find = true;
    } );

    return nextorder;
}

export function nextItem( array , current_order ) {
    let nextItem = null;
    let dobreak = false;
    let find = false;
    array.forEach( ( item ) => 
    {
        if( find )
        {
            nextItem = item;
            dobreak = true;
            find = false;
        } 
        if( dobreak ) return false;
        if( current_order == item.order ) find = true;
    } );

    return nextItem;
}

export function handleStringGlobal( e , name ) {
    return AppState[name] = e.target.value;
}

export function setStringGlobal( string , name ) {
    return AppState[name] = string;
}

export function handleValueGlobal( e , name ) {
    return AppState[name] = e.target.value;
}

export function handleFloatGlobal( e , name ) {
    return AppState[name] = parseFloat( e.target.value );
}

export function toast( string ) {
    AppToaster.show({ "message": string});
}

export function ft_alert( text )
{
    store.alert_open = true;
    store.alert_content = text;
}

export function ft_confirm( text , func )
{
    store.confirm_open = true;
    store.confirm_content = text;
    store.confirm_confirm = func;

}

export function rclean( string )
{
    string = (string+'').replace(/([1-9][0-9]*)0+$/,'$1');
    return string;
}


export function toInt( string )
{
    return parseInt( string , 10 );
}

export function intval( string )
{
    return toInt( string  );
}

export function showApiError( data )
{
    if( data && data.code && parseInt( data.code , 10 ) !== 0 )
    {
        if( data.args )
            toast( sprintf( data.info  , ...data.args) );
        else
            toast( data.message );   
            
        if( parseInt( data.code , 10 ) === 40301 )
        {
            if(window.confirm( "未登入或权限不足，要转向登入页面吗？" ))
            {
                window.location = '/login';
            }
        }
            

        return true;    
    }
    else
        return false;
}

export function isApiOk( data )
{
    return parseInt( data.code , 10 ) === 0;
}


export function inGroup( id , groups ) {
    let ret = false;
    groups.forEach(( group ) =>
    {
        if( parseInt( group.value, 10 ) === parseInt( id, 10 ) ) ret = true;
    } );
    return ret;
}

export function groupsToId( groups )
{
    if( !Array.isArray( groups) ) return false;
    
    let ids = [];
    groups.forEach(( group ) =>
    {
        ids.push( parseInt( group.value, 10 ) );
    } );

    return ids;
}

export function strip(html)
{
    var doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
 }

// export const readFile = (inputFile) => {
//     const temporaryFileReader = new FileReader();
  
//     return new Promise((resolve, reject) => {
//       temporaryFileReader.onerror = () => {
//         temporaryFileReader.abort();
//         reject(new DOMException("Problem parsing input file."));
//       };
  
//       temporaryFileReader.onload = () => {
//         resolve(temporaryFileReader.result);
//       };
//       temporaryFileReader.readAsBinaryString(inputFile);
//     });
// };

