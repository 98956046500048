import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

@withRouter
@inject("store")
@observer
export default class CenterLayout extends Component
{
    render()
    {
        const main = this.props.main ? this.props.main : null; 
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}