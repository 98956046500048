import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import { isApiOk , showApiError, toast, intval } from '../util/Function';
import {  Button } from "@blueprintjs/core";

import DocumentTitle from 'react-document-title';

@withRouter
@inject("store")
@observer
export default class OpenId extends Component
{
    
    state = {"config":false,"paid":false};

    async componentDidMount()
    {
        const params = new URLSearchParams(this.props.location.search);
        const pid = params.get('state') ;
        const code = params.get('code') ;
        
        if( code && pid > 0 )
        {
            const { data } = await this.props.store.get_openid_by_code( code , pid );

            if( isApiOk( data ) )
            {
                const config = data.data;
                
                if( config.json )
                {
                    this.setState( {config} );
                    
                }
                else
                {
                    console.log( "data" , data , "data.data" , data.data );
                }
            }
            else
                showApiError( data ); 
        }
    }

    pay()
    {
        let config = this.state.config;
        let jsinfo = JSON.parse( config.json );

        let that = this;

        function onBridgeReady() {
            /*global WeixinJSBridge:true*/
            /*eslint no-undef: "error"*/
            WeixinJSBridge.invoke(
               'getBrandWCPayRequest', {
                  "appId":jsinfo.appId,     //公众号名称，由商户传入     
                  "timeStamp":jsinfo.timeStamp,         //时间戳，自1970年以来的秒数     
                  "nonceStr":jsinfo.nonceStr, //随机串     
                  "package":jsinfo.package,     
                  "signType":"MD5",         //微信签名方式：     
                  "paySign":jsinfo.paySign //微信签名 
               },
               (res)=>{
               if(res.err_msg == "get_brand_wcpay_request:ok" ){
                    // 转向到我的课程
                    // toast("支付完成");
                    that.setState({"paid":true});
                    // history.push("/lesson/list");
               }else
               {
                   alert( JSON.stringify(res) );
               } 
            }); 
        }

        if (typeof WeixinJSBridge == "undefined"){
            if( document.addEventListener ){
                document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            }else if (document.attachEvent){
                document.attachEvent('WeixinJSBridgeReady', onBridgeReady); 
                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
         }else{
            onBridgeReady();
         }
    }
    
    render()
    {
        const main = <div className="pay-box">

        { this.state.paid ? <div className="paid-notice">
            <div>请长按关注服务号[方糖]，以接收发货通知。</div>
            <img src="/image/mpcover.png" />
            
        </div> :  <Button onClick={()=>this.pay()}>点此开始支付</Button> }
        
        
        
        
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}