import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import Column2Layout from '../component/Column2Layout'; 
import { Icon, AnchorButton, FormGroup, InputGroup, Switch, RadioGroup, Radio, Checkbox, TextArea, Button, Intent, ControlGroup, Tabs , Tab } from "@blueprintjs/core";
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleValueGlobal,handleIsGlobal, toast, intval, ft_confirm } from '../util/Function';
import OpenFileButton from '../component/OpenFileButton';
import CodeTable from '../component/CodeTable'; 


@withRouter
@inject("store")
@observer
export default class ProjectStock extends Component
{
    state = {"codes":false,"selected_tab":"lived"};
    
    async componentDidMount()
    {
        const id = intval(this.props.match.params.id);
        if( id > 0 )
        {
            this.id = id;
            const { data } = await this.props.store.project_detail( id );
            if( isApiOk( data ) )
            {
                this.props.store.project_set_data( data.data );
                await this.loadStock( id );
            }
            else
                showApiError( data ); 
        }
    }

    async loadStock( id )
    {
        const { data } = await this.props.store.project_stock( id );
        if( isApiOk( data ) )
        {
            this.setState({"codes":data.data});
        }
        else
            showApiError( data ); 
    }

    updated()
    {
        this.loadStock( this.id );
    } 

    selected( e )
    {
        this.setState({"selected_tab":e});
    }

    render()
    {
        const store = this.props.store;
        const live_count = this.state.codes && this.state.codes.length ?  " "+ this.state.codes.length : "";

        const main = <div className="wrapper std-form">
        
        <Tabs id="code-table"  selectedTabId={this.state.selected_tab} onChange={(e)=>this.selected(e)}>

        <Tab id="lived" title={"可用"+live_count} panel={<CodeTable data={this.state.codes && this.state.codes.filter( item => parseInt(item.order_id) == 0 )} onUpdated={()=>this.updated()} project_id={this.id} />} />
        
        
        <Tab id="used" title="已分配" panel={<CodeTable data={this.state.codes && this.state.codes.filter( item => parseInt(item.order_id)  > 0 )} onUpdated={()=>this.updated()} project_id={this.id} />} />

        <Tab id="all" title="全部" panel={<CodeTable data={this.state.codes} onUpdated={()=>this.updated()} project_id={this.id} />} />
        

        </Tabs> 
        </div>;
        
        const side = <div><div className="wrapper info-box">

        <OpenFileButton icon="document" text="通过TXT导入" accept=".txt" project_id={store.project_id} onUpdated={()=>this.updated()}/>    
        </div></div>;

        const title = <><span>库存管理</span><Icon icon="chevron-right" /><span className="active">{store.project_name}</span></>;

        return <Column2Layout main={main} side={side} title={title} />;
    }
}