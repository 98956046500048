import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';


@withRouter
@inject("store")
@observer
export default class StatusIcon extends Component
{
    render()
    {
        const status = this.props.data;
        return <div className="status-icon">
            { status == 0 && <span>支付成功</span> }
            { status == 1 && <span>准备退款</span> }
            { status == 2 && <span>正在退款</span> }
            { status == 3 && <span>退款完成</span> }
            { status == 4 && <span>退款失败</span> }
        </div>;
    }
}