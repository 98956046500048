import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

@withRouter
@inject("store")
@observer
export default class NodataBox extends Component
{
    render()
    {
        const text = this.props.text ? this.props.text : "还没有可用的数据";
        
        return <div className="no-data">
        <div className="text">{text}</div>
        <img src="/image/talkman.png" className="man"/>
        </div>;
    }
}