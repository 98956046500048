import { observable, action } from "mobx";
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/zh-cn.js';
moment.locale('zh-cn');

const API_BASE = process.env.REACT_APP_API_BASE ;

class AppState
{
    @observable appname = "方糖::Next";  
    @observable token = null;

    // user info
    @observable uid = 0;
    @observable level = 0;
    @observable nickname = "";
    @observable avatar = ""; 

    @observable alert_open = false;
    @observable alert_content = '';
    
    @observable confirm_open = false;
    @observable confirm_content = '';
    @observable confirm_confirm = null;
    
    @observable project_name = '';
    @observable project_subtitle = '';
    @observable project_target_amount = 0;
    @observable project_end_date = moment().add(3, 'months').format("YYYY-MM-DD");
    @observable code_end_date = moment().add(3, 'months').format("YYYY-MM-DD");
    @observable project_desp = "";
    @observable project_sale_code = "";
    @observable project_sale_url = "";
    @observable project_video_url = "";
    @observable project_item1_price = 0;
    @observable project_item1_name = '';
    @observable project_item1_detail = '';

    @observable project_type = 1; // 1 为预售模式，2位标准模式
    @observable project_enabled = 1;
    @observable project_can_buy = 1;
    @observable project_is_in_sale = 1;
    @observable project_is_refunding = 0;

    constructor()
    {
        this.loadUser();
    }

    @action
    project_data_init()
    {
        this.project_name = '';
        this.project_subtitle = '';
        this.project_target_amount = 0;
        this.project_end_date = moment().format("YYYY-MM-DD");
        this.project_desp = "";
        this.project_sale_code = "";
        this.project_sale_url = "";
        this.project_video_url = "";
        this.project_item1_price = 0;
        this.project_item1_name = '';
        this.project_item1_detail = '';
        this.project_stock_count = 0;
        this.project_to_dispatch_count = 0;
    }

    @action
    project_set_data( data )
    {
        this.project_id = data.id;
        this.project_name = data.name;
        this.project_subtitle = data.subtitle;
        this.project_type = data.type;
        this.project_stock_count = data.stock_count;
        this.project_to_dispatch_count = data.to_dispatch_count;
        this.project_target_amount = ( data.target_amount_cent/ 100).toFixed(0);
        this.project_current_amount = ( data.current_amount_cent/ 100).toFixed(0);

        this.project_end_date = moment(data.end_date).format("YYYY-MM-DD") ;;
        this.project_desp = data.desp;
        this.project_sale_code = data.sale_code;
        this.project_sale_url = data.sale_url;
        this.project_video_url = data.video_url;
        this.project_item1_price = ( data.item1_price_cent/ 100).toFixed(2);;
        this.project_item1_name = data.item1_name;
        this.project_item1_detail = data.item1_detail;


        this.project_enabled = data.enabled;
        this.project_can_buy = data.can_buy;
        this.project_is_in_sale = data.is_in_sale;
        this.project_is_refunding = data.is_refunding;

        
    }


    @action
    setUser( user )
    {
        this.uid = user.uid;
        this.level = user.level;
        this.nickname = user.nickname;
        this.avatar = user.avatar;
        this.token = user.token;
        
        window.localStorage.setItem( "FTUSER" , JSON.stringify( user ) );
    }

    @action
    loadUser()
    {
        let data = false;
        const data_json = window.localStorage.getItem( "FTUSER" );
        if( data_json ) data = JSON.parse( data_json );
        if( data ) this.setUser( data );
    }

    @action
    cleanUser()
    {
        window.localStorage.removeItem("FTUSER");
        this.uid = 0;
        this.level = 0;
        this.nickname = "";
        this.avatar = "";
        this.token = "";
    }
    
    async loadQRCode()
    {
        var params = new URLSearchParams();
        return await axios.get( API_BASE + 'user/signin' );
    }

    async checkQRCode()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        
        return await axios.post( API_BASE + 'sso/check' , params );
    }

    async project_list()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        return await axios.post( API_BASE + 'project/list' , params );
    }

    
    async project_upload_stock( id , codes , end_date )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("codes" , JSON.stringify(codes));
        params.append("end_date" , moment(end_date).format("YYYY-MM-DD") );
        return await axios.post( API_BASE + 'project/stock/upload' , params );
    }

    // project_dispatch
    async project_dispatch( id  )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'project/dispatch' , params );
    }

    async project_toggle_buy( id , value )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("value" , value);
        return await axios.post( API_BASE + 'project/toggle_buy' , params );
    }

    async project_toggle_type( id , value )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("value" , value);
        return await axios.post( API_BASE + 'project/toggle_type' , params );
    }

    async project_toggle_sale( id , value )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("value" , value);
        return await axios.post( API_BASE + 'project/toggle_sale' , params );
    }

    async project_send_notice( id , notice , url = '' )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("notice" , notice);
        params.append("url" , url);
        return await axios.post( API_BASE + 'project/notice' , params );
    }

    async project_refund( id , info , password = '' )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("info" , info);
        params.append("password" , password);
        return await axios.post( API_BASE + 'project/refund' , params );
    }

    async project_detail( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'project/detail' , params );
    }

    async project_stock( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'project/stock/list' , params );
    }

    async stock_delete( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'project/stock/delete' , params );
    }

    

    async get_openid_by_code( code , pid )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("code" , code);
        params.append("pid" , pid);
        return await axios.post( API_BASE + 'openid' , params );
    }

    

    async order( openid )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("openid" , openid);
        return await axios.post( API_BASE + 'order' , params );
    }

    async project_landing( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'project/landing' , params );
    }

    async project_order( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'project/pay' , params );
    }

    async project_order_js( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'project/jspay' , params );
    }

    async raw_get( url )
    {
        return await axios.get( url );
    }



    async project_save()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        // 开始组装数据
        params.append("name" , this.project_name);
        params.append("subtitle" , this.project_subtitle);
        params.append("desp" , this.project_desp);
        params.append("target_amount_cent" , this.project_target_amount * 100 );
        params.append("end_date" , this.project_end_date);

        params.append("item1_name" , this.project_item1_name);
        params.append("item1_detail" , this.project_item1_detail);
        params.append("item1_price_cent" , this.project_item1_price * 100);
        params.append("video_url" , this.project_video_url);


        return await axios.post( API_BASE + 'project/save' , params );
    }

    async project_update()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        // 开始组装数据
        params.append("id" , this.project_id);
        params.append("name" , this.project_name);
        params.append("subtitle" , this.project_subtitle);
        params.append("desp" , this.project_desp);
        params.append("sale_code" , this.project_sale_code);
        params.append("sale_url" , this.project_sale_url);
        params.append("target_amount_cent" , this.project_target_amount * 100 );
        params.append("end_date" , this.project_end_date);

        params.append("item1_name" , this.project_item1_name);
        params.append("item1_detail" , this.project_item1_detail);
        params.append("item1_price_cent" , this.project_item1_price * 100);
        params.append("video_url" , this.project_video_url);


        return await axios.post( API_BASE + 'project/update' , params );
    }

    async uploadImage( blob )
    {
        let formData = new FormData();
        formData.append("image", blob );
        formData.append("token", this.token);
        return await axios.post( API_BASE + 'image/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
    }
}

export default new AppState();