import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import CenterLayout from '../component/CenterLayout'; 
import { toast , isApiOk , showApiError } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class Login extends Component
{
    state = {"qr_url":""};

    async componentDidMount()
    {
        console.log("loaded");
        const { data } = await this.props.store.loadQRCode();
        if( isApiOk( data ) )
        {
            if( data.data.qr_url )
            {
                this.setState({"qr_url":data.data.qr_url});
                this.props.store.token = data.data.token;

                // 添加定时检测任务
                this.check = setInterval( ()=>{this.checkLogin()} , 1000*5 );
            }
        }
        else showApiError( data );
    }

    async checkLogin()
    {
        const { data } = await this.props.store.checkQRCode();
        if( isApiOk( data ) )
        {
            if( data.data )
            {
                this.cleanCheck();

                const { result } = data.data;
                result.token = this.props.store.token;
                this.props.store.setUser( result );

                toast("欢迎回来，"+result.nickname);
                // 
                if( result.nickname < 2 )
                    this.props.history.replace("/memberonly");
                else
                    this.props.history.replace("/project");
            }
        } else showApiError( data );
    }

    cleanCheck()
    {
        clearInterval( this.check );
    }

    componentWillUnmount()
    {
        this.cleanCheck();
    }
    
    render()
    {
        const main = <div className="center-wrapper">
        <div className="center-box">
        <img src="/image/logoman.png" className="login-logo" alt="logoman" />
        <div className="login-box">
            <h1 className="m30">方糖::Next · 预售一点未来</h1>
            {this.state.qr_url && this.state.qr_url.length > 0 && <img src={this.state.qr_url} className="qrcode" alt="sample-qr-code"/>}
            <p className="explain">使用微信扫码登入</p>
        </div>
        
        </div></div>;
        return <CenterLayout main={main} />;
    }
}