import React, { Component,Fragment } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import Column2Layout from '../component/Column2Layout'; 
import { Icon, AnchorButton, FormGroup, InputGroup, Switch, RadioGroup, Radio, Checkbox, TextArea, Button, Intent, ControlGroup } from "@blueprintjs/core";
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleValueGlobal,handleIsGlobal, toast, intval, ft_confirm } from '../util/Function';
@withRouter
@inject("store")
@observer
export default class ProjectManage extends Component
{
    state = { "notice":"" , "url":"" , "refund_info":"" , "refund_password":"" };
    
    async componentDidMount()
    {
        const id = intval(this.props.match.params.id);
        if( id > 0 )
        {
            const { data } = await this.props.store.project_detail( id );
            if( isApiOk( data ) )
            {
                this.props.store.project_set_data( data.data );
                console.log( " this.project_end_date " , this.props.store.project_end_date );
            }
            else
                showApiError( data ); 
        }
    }

    async toggle_buy( id , e )
    {
        
        let value = 0;
        if( e.target.checked )
            value = 1;
        
        const { data } = await this.props.store.project_toggle_buy( id , value );
        
        if( isApiOk( data ) )
        {
            this.props.store.project_can_buy = value;
        }
        else
            showApiError( data ); 
    }

    async toggle_sale( id , e )
    {
        let value = 0;
        if( e.target.checked )
            value = 1;
        
        const { data } = await this.props.store.project_toggle_sale( id , value );
        
        if( isApiOk( data ) )
        {
            this.props.store.project_is_in_sale = value;
        }
        else
            showApiError( data ); 
    }

    async toggle_type( id , e )
    {
        console.log( e.target.value ); 
        const value =  e.target.value ;
        // let value = 0;
        // if( e.target.checked )
        //     value = 1;
        
        const { data } = await this.props.store.project_toggle_type( id ,  value  );
        
        if( isApiOk( data ) )
        {
            this.props.store.project_type = value;
        }
        else
            showApiError( data ); 
    }

    async send_notice( id , notice , url )
    {
        ft_confirm( "确定要发送通知？项目已付费用户均会收到" , async ()=>
        {
            const { data } = await this.props.store.project_send_notice( id , notice , url );
        
            if( isApiOk( data ) )
            {
                toast("通知已经放入队列");
                this.setState( {"notice":"","url":""} );
            }
            else
                showApiError( data ); 
        } );
        
        
    }

    async dispatch( id )
    {
        // 
        const { data } = await this.props.store.project_dispatch( id  );
        
        if( isApiOk( data ) )
        {
            window.location.reload();
        }
        else
            showApiError( data ); 
    }

    async refund( id , info , password )
    {
        ft_confirm( "确定退款吗？退款不可恢复" , async ()=>
        {
            const { data } = await this.props.store.project_refund( id , info , password );
        
            if( isApiOk( data ) )
            {
                toast("已经开始批量退款");
                this.setState( {"refund_password":""} );
            }
            else
                showApiError( data ); 
        } );
    }
    
    render()
    {
        const store = this.props.store;
        
        const main = <div className="wrapper std-form">
         <RadioGroup inline={true}
            label="销售模式"
            onChange={(e)=>this.toggle_type( store.project_id , e )}
            selectedValue={store.project_type}
        >
            <Radio label="预售" value="1" />
            <Radio label="标准销售" value="2" />
        </RadioGroup>    

        <div className="std-hr form-div"/>

        <FormGroup
            label="商品状态"
            labelFor="can_buy" 
        >
            <Switch  label="是否允许购买" checked={store.project_can_buy == 1} onChange={(e)=>this.toggle_buy( store.project_id , e )} />
        </FormGroup>

        { store.project_type == 1 ? <Fragment>
            <FormGroup
            label="预售状态"
            labelFor="is_end" 
        >
            <Switch  label="开启预售（关闭后预售结束，暂停关闭「购买」即可）" checked={store.project_is_in_sale == 1} onChange={(e)=>this.toggle_sale( store.project_id , e )} />
        </FormGroup>

        { ( store.project_can_buy != 1 && store.project_is_in_sale != 1 ) ? <><div className="std-hr form-div"/>

        <FormGroup
            label="发起退款"
            labelFor="send"
        >
        <InputGroup id="refund_info" placeholder="退款说明"  large="true" onChange={(e)=>this.setState( {"refund_info":e.target.value} )} value={this.state.refund_info}   />

        <InputGroup id="refund_password" placeholder="退款密码"  large="true" className="mt10" type="password" onChange={(e)=>this.setState( {"refund_password":e.target.value} )} value={this.state.refund_password}   />
        </FormGroup>

        <FormGroup>
        <Button large="true" intent={Intent.PRIMARY} onClick={()=>this.refund( store.project_id , this.state.refund_info , this.state.refund_password )}>开始退款</Button>
        </FormGroup> 

        </> : <div>关闭购买状态和预售状态才能开始退款</div> }
        <div className="std-hr form-div"/>

        { store.project_to_dispatch_count > 0 && <>
        <h2> { store.project_to_dispatch_count }份订单待发货(总销售额 { store.project_current_amount } )，可用库存 { store.project_stock_count } </h2> 
        <Button  text="现在发货" large={true} icon="airplane" onClick={()=>this.dispatch( store.project_id )} /> 
        <div className="std-hr form-div"/>
        </>}
        
        <FormGroup
            label="更新通知"
            labelFor="notice"
        >
        <TextArea className="std-area" large="true" placeholder="通知内容，可用参数：{openid}" onChange={(e)=>this.setState( {"notice":e.target.value} )} value={this.state.notice} />
        <InputGroup className="mt10" id="notice_url" placeholder="通知URL，可用参数：{openid}" large="true" onChange={(e)=>this.setState( {"url":e.target.value} )} value={this.state.url}  />
        </FormGroup>

        <FormGroup>
        <Button large="true" intent={Intent.PRIMARY} onClick={()=>this.send_notice( store.project_id , this.state.notice , this.state.url )}>发送通知</Button>
        </FormGroup> 
        </Fragment> : null }
        
        

        

        



        </div>;

        const side = <div><div className="wrapper info-box">
        <AnchorButton large="true" icon="shop" minimal={true} onClick={()=>this.props.history.push("/project/stock/"+store.project_id)} >库存管理</AnchorButton>
        </div></div>;

        const title = <><span>我的项目</span><Icon icon="chevron-right" /><span className="active">{store.project_name}</span></>;

        return <Column2Layout main={main} side={side} title={title} />;
    }
}