import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter, NavLink } from 'react-router-dom';

@withRouter
@inject("store")
@observer
export default class MenuList extends Component
{
    render()
    {
        return <ul className="std-top-menu">
            <li><NavLink to="/project" activeClassName="actived">我的项目</NavLink></li>
            {/* <li><NavLink to="/order" activeClassName="actived">订单</NavLink></li>
            <li><NavLink to="/question" activeClassName="actived">答疑</NavLink></li>
            <li><NavLink to="/settings" activeClassName="actived">系统设置</NavLink></li> */}
        </ul>;
    }
}