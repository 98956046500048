import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import moment from 'moment';
import 'moment/locale/zh-cn.js';
moment.locale('zh-cn');

@withRouter
@inject("store")
@observer
export default class DateField extends Component
{
    constructor( props )
    {
        super( props );
        const date = this.props.value ? moment( this.props.value ) : moment();

        this.state = { "date":date };
    }

    componentDidUpdate( prevProps )
    {
        if( this.props.value != prevProps.value )
        {
            console.log("props updated");
        
            const date = this.props.value ? moment( this.props.value ) : moment();
            this.setState ({ date });
        }
    }

    change( date )
    {
        this.setState({ "date":date });
        if( this.props.onChange ) this.props.onChange( moment(date).format("YYYY-MM-DD") );
    }
    
    render()
    {
        return <SingleDatePicker
        date={this.state.date} // momentPropTypes.momentObj or null
        onDateChange={date => this.change( date )} // PropTypes.func.isRequired
        focused={this.state.focused} // PropTypes.bool
        onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
        id="datePicker" // PropTypes.string.isRequired,
        placeholder={this.props.placeholder}
      />;
    }
}