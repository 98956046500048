import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { HTMLTable, Checkbox, Icon, Menu, MenuItem, MenuDivider, Popover, Position, Tooltip, Button, Overlay  } from "@blueprintjs/core";
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleIsGlobal, toast, intval } from '../util/Function';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import moment from 'moment';
import 'moment/locale/zh-cn.js';
moment.locale('zh-cn');

@withRouter
@inject("store")
@observer
export default class CodeTable extends Component
{
    
    async delete( id )
    {
        if( window.confirm( "删除以后不能恢复，只能重新导入。继续？" ) )
        {
            const { data } = await this.props.store.stock_delete( id );
        
            if( isApiOk( data ) )
            {
                if( this.props.onUpdated ) this.props.onUpdated();
            }
            else
                showApiError( data );
        }
    }
    
    render()
    {
        const code_list = this.props.data;
        if( !code_list ) return null;

        return <HTMLTable interactive={true} className="stock-table">
            <thead>
            <tr>
                <td className="stock-checkbox-col"><Checkbox className="stock-clean"/></td>
                <td>领课码</td>
                <td>分配</td>
                <td>批次</td>
                <td className="center">过期时间</td>
                <td></td>
            </tr>
            </thead>

            <tbody>

            { code_list && code_list.map( item =>
                {
                    const classname = parseInt(item.order_id) > 1 ? 'used' : 'notused';

                    
                    return <tr key={item.id}>
                        <td><Checkbox className="stock-clean"/></td>
                        <td>
                        <Tooltip content={item.notes} position={Position.TOP}>
                        <span className={ classname + " right-5"}>{item.code.substring(0,18)}...</span></Tooltip>
                        
                        <CopyToClipboard text={item.code} onCopy={()=>
                            {
                                toast("领课码已复制到剪贴板");
                            }}><Button small={true} minimal={true} icon="clipboard" className="stock-icon" /></CopyToClipboard> </td>

                        <td>{item.order_id > 0 ? item.order_id : "-" }</td>
                        
                        <td>{item.dispatch_id > 0 ? item.dispatch_id : "-" }</td>    
                        <td className="center">{moment(item.end_at).format("YYYY-MM-DD")}</td>
                        <td className="actions">
                            <Popover content={<Menu>
                
                                <MenuItem icon="cross" text="删除" onClick={()=>this.delete(item.id)}/>
                            
                            </Menu>} position={Position.BOTTOM}>
                                <Button small={true} minimal={true} icon="more" className="stock-icon" />
                            </Popover>
                        </td>
                    </tr>
                }) 
            }

            
            
            </tbody>

            {/* <tfoot>
            <tr>
                <td className="cic-checkbox-col"><Icon icon="arrow-right" className="cic-icon" /></td>
                <td colSpan="6">归档&nbsp;&nbsp;&nbsp;恢复</td>
               
            </tr>
            </tfoot> */}


        </HTMLTable>;
    }
}